<template>
    <div>
        <errors-bag-list/>

        <b-card v-if="salary" class="mb-1" >
            <b-card-header class="p-2 text-center">
                <h3>Редактирование промежутока стоимости для должности "{{ salary.work_position.name }}"</h3>
            </b-card-header>

            <b-card-body>
                <salary-rates-form v-model="salaryRate" :salary="salary"/>
            </b-card-body>
        </b-card>

        <control-panel>
            <b-button variant="secondary" @click="$router.go( -1 )">Назад</b-button>
            <b-button variant="danger" @click="onDelete" v-if='isDiff && isFeatureRate'>Удалить</b-button>
            <b-button variant="success" @click="saveSalaryRate">Сохранить</b-button>
        </control-panel>
    </div>
</template>

<script>
    import moment from 'moment';

    import ControlPanel from '@components/_common/ControlPanel';
    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import ErrorsBag from '@utils/errorsBag';
    import { SALARY_ENDPOINT } from '@utils/endpoints';

    import SalaryRatesForm from './SalaryRatesForm';

    const DATE_FORMAT = 'YYYY-MM-DD';

    const DEFAULT_SALARY_RATE = {
        work_position_id: null,
        rate_per_hour: 0,
        valid_from: null
    };

    export default {
        name: "SalaryRatesEdit",
        props: [ 'id', 'rate_id' ],
        components: { ControlPanel, ErrorsBagList, SalaryRatesForm },
        computed: {
            isDiff() {
                return this.salaryRate && JSON.stringify( this.salaryRate ) !== JSON.stringify( DEFAULT_SALARY_RATE );
            },
            isFeatureRate() {
                let currentDate = moment().toISOString();

                return moment( this.salaryRate.valid_from ).isAfter( currentDate );
            }
        },
        data() {
            return {
                ErrorsBag,

                salary: {
                    work_position: {},
                },
                salaryRate: null,
            };
        },
        methods: {
            saveSalaryRate() {
                ErrorsBag.discard();

                let validFrom = null;
                if ( this.salaryRate.valid_from ) {
                    validFrom = moment( this.salaryRate.valid_from ).format( DATE_FORMAT );
                }

                let params = {
                    valid_from: validFrom,
                    rate_per_hour: this.salaryRate.rate_per_hour
                };

                this.$http
                    .put( SALARY_ENDPOINT + '/rates/' + this.rate_id, params )
                    .then(
                        () => this.$router.push( { name: 'SalaryRatesList' } ),
                        response => ErrorsBag.fill( response.body )
                    );
            },
            onDelete() {
                ErrorsBag.discard();

                this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить промежуток стоимости?', {
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    cancelVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(value => {
                        if (value === true) {
                            this.$http
                                .delete(SALARY_ENDPOINT + '/rates/' + this.rate_id)
                                .then(() => {
                                    this.$router.push({name: 'SalaryRatesList'});
                                    this.$bvToast.toast('Удаление промежутка стоимости выполнено успешно', {variant: 'success'});
                                }, response => ErrorsBag.fill(response.body));
                        }
                    });
            },
            fetchSalaryRate( discard = true ) {
                if ( discard ) {
                    ErrorsBag.discard();
                }

                this.salaryRate = structuredClone(DEFAULT_SALARY_RATE);

                let params = {
                    with: [ 'workPosition' ]
                };

                this.$http
                    .get( SALARY_ENDPOINT + '/rates/' + this.rate_id, { params } )
                    .then(
                        response => this.salaryRate = response.data.data,
                        response => ErrorsBag.fill( response.body )
                    );
            },
            fetchSalarySetting() {
                ErrorsBag.discard();

                let params = { with: [ 'workPosition', 'followingSalaryRates' ] };

                return this.$http
                    .get( SALARY_ENDPOINT + '/settings/' + this.id, { params } )
                    .then( response => this.salary = response.data.data, response => ErrorsBag.fill( response.body ) );

            },
        },
        mounted() {
            this
                .fetchSalarySetting()
                .then( () => this.fetchSalaryRate( false ) );
        }
    }
</script>
